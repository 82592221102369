//== Global Variables
var $animationDuration = 100;
var $headerBranding = $('#header-branding');
var $headerButton = $('#header-button');

//== Functions
if (typeof Object.assign != 'function') {
  Object.assign = function(target) {
    'use strict';
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (var index = 1; index < arguments.length; index++) {
      var source = arguments[index];
      if (source != null) {
        for (var key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

//== Document Ready
$(document).ready(function(){


    //-- Header (Sales Funnel)
    if($('body').hasClass('sf')) {
        var $headroom  = new Headroom(document.querySelector('header'),{
            classes: {
                'initial': 'animated',
                'pinned': 'slideInDown',
                'unpinned': 'slideOutUp'
            },
            offset: 0,
            tolerance: 25
        });
        $headroom.init();
    }

    //-- Jump links
    $('.jump-link').click(function(e) {
        e.preventDefault();
        var $target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: $($target).offset().top
        }, 250);
    });

    // Dropdown
    $('select').dropdown({
        native: true
    });

/*
    // Insurance boxes (show/hide product details)
    $('.form-box-insurance-toggle').click(function(e) {
        e.preventDefault();
        var $box = $(this).closest('.form-box-insurance');
        var $target = $(this).closest('.form-box-insurance-text').find('.form-box-insurance-text-content');
        if($box.hasClass('form-box-insurance-active')) {
            $target.slideUp(250);
            $box.removeClass('form-box-insurance-active');
            $(this).html($(this).attr('data-label-default'));
            $(this).removeClass('nav-link-up').addClass('nav-link-down').blur();
        } else {
            $target.slideDown(250);
            $box.addClass('form-box-insurance-active');
            $(this).html($(this).attr('data-label-highlight'));
            $(this).removeClass('nav-link-down').addClass('nav-link-up').blur();
        }
    });

    // Insurance boxes (enable/disable product)
    $('.form-box-insurance-trigger').change(function(e) {
        e.preventDefault();
        var $box = $(this).closest('.form-box-insurance');
        var $action = $(this).attr('data-form-box-insurance-trigger-action');
        if($(this).prop('checked')) {
            if($action == 'enable') {
                $box.removeClass('box-default').addClass('box-highlight');
            } else {
                $box.removeClass('box-highlight').addClass('box-default');
            }
        }
    });

    // Card image slider
    $('.card-image-slider').slick({
        adaptiveHeight: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        fade: true,
    });
*/
});

//== Handle key strokes
$(document).keydown(function(e){
    //-- Hide elements if ESC key is pressed
    if(e.keyCode === 27) {
        // Do something
    }
});

function formatVal(N, p) {
  var Nn = parseFloat(N).toFixed(p);
  var sRegExpT = new RegExp('(-?[0-9]+)([0-9]{3})');
  while(sRegExpT.test(Nn)) {
      Nn = Nn.replace(sRegExpT, '$1\'$2');
  }
  return Nn;
}
